export const environment = {
  production: false,
  cdnUrl: 'https://dm1o5xcphhn2z.cloudfront.net',
  apiUrl: 'https://dccz3o5k72.execute-api.us-east-1.amazonaws.com',
  stripePaymentLink: 'https://buy.stripe.com/3csfZF3lLgrJcJG16y',
  gtmId: 'GTM-T9HP73D',
  previewUrl: 'https://s3.amazonaws.com/dev-cybertruck.wrapmate.com/thumbnails/preview',
  ogUrl: 'https://dev-cybertruck.wrapmate.com/configurator?color=',
  impactPage: 'https://dev.wrapmate.com/express/impact/tesla/2023/cybertruck/tesla_2023_cybertruck_truck_base/coverage/large/roof/personal/'
};
